<!-- 主持人-->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="name"
        placeholder="请输入名称"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="add"
        >新增</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column type="index" label="ID" width="60"> </el-table-column>
      <el-table-column prop="name" label="名称" width="130"> </el-table-column>
      <el-table-column prop="image" label="图片" width="80">
        <template slot-scope="scope">
          <img
            style="width: 50px; height: 50px"
            :src="$httpUrl + '/image/' + scope.row.image"
            alt=""
          />
        </template>
      </el-table-column>
      <!-- <el-table-column prop="heat" label="热度" width="100"> </el-table-column> -->
      <el-table-column prop="context" label="个人简介" width="180">
      </el-table-column>
      <!-- <el-table-column prop="number" label="主持场数" width="100">
      </el-table-column> -->
      <el-table-column prop="s_name" label="店铺名" width="180">
      </el-table-column>
      <el-table-column prop="d_idnames" label="熟悉剧本" width="180">
      </el-table-column>
      <el-table-column prop="operate" label="操作">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="mod(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination
    ><el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-col :span="20">
            <el-input v-model="form.name"></el-input>
          </el-col>
        </el-form-item>
        <!-- <el-form-item label="热度" prop="heat">
          <el-col :span="20">
            <el-input v-model="form.heat" disabled></el-input>
          </el-col>
        </el-form-item> -->
        <el-form-item label="图片" prop="image">
          <el-col :span="20">
            <imgUploader
              :iga="form.image"
              ref="pic2"
              :width="200"
              :height="200"
              :limit="1"
              @change="img()"
            >
            </imgUploader>
          </el-col>
        </el-form-item>
        <el-form-item label="个人简介" prop="context">
          <el-col :span="20">
            <el-input type="textarea" v-model="form.context"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="熟悉剧本" prop="d_idnames">
          <el-col :span="10" style="width: 200px">
            <el-select
              v-model="form.d_idnames"
              multiple
              placeholder="请选择"
              @change="changedrama"
            >
              <el-option
                v-for="item in dramas"
                :key="item.name"
                :label="item.name"
                :value="item.name"
                :class="[item.selected ? 'selected' : '']"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <!-- <el-form-item label="主持场数" prop="number">
          <el-col :span="20">
            <el-input v-model="form.number" disabled></el-input>
          </el-col>
        </el-form-item> -->
        <el-form-item label="店铺名" prop="s_name">
          <el-col :span="20">
            <el-input v-model="form.s_name" disabled></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import imgUploader from "../imgUploader/imgUploader.vue";
export default {
  name: "StorageManage",
  components: {
    imgUploader,
  },
  data() {
    return {
      tableData: [
        {
          id: 1,
          name: "万亿达",
          heat: "156",
          context: "aslhgblkasnldnk赛道",
          number: "10",
          s_name: "撒旦法剧本杀",
          s_id: 2,
          image: "图片",
        },
        {
          id: 100,
          name: "万亿asdasd达",
          heat: "156",
          context: "aslldnk赛道",
          number: "10",
          s_name: "撒旦法剧本杀",
          s_id: 2,
          image: "图片",
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      name: "",
      dramas: [],
      centerDialogVisible: false,
      form: {
        id: "",
        name: "",
        heat: 0,
        context: "",
        number: 0,
        s_name: "",
        image: [],
        d_idnames: [],
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        // heat: [{ required: true, message: "请输入热度", trigger: "blur" }],
        context: [
          { required: true, message: "请输入个人简介", trigger: "blur" },
        ],
        // number: [
        //   { required: true, message: "请输入主持场数", trigger: "blur" },
        // ],
        s_name: [{ required: true, message: "请输入店铺名", trigger: "blur" }],
        image: [{ required: true, message: "请选择图片", trigger: "blur" }],
      },
    };
  },
  methods: {
    changedrama() {
      console.log(this.form.d_idnames);
      console.log(this.dramas);
      let dramas2 = [];
      this.dramas.forEach((x) => {
        let out_x = x;
        if (
          this.form.d_idnames.filter((y) => {
            return y.trim() == out_x.name;
          }).length > 0
        ) {
          out_x.selected = true;
        } else {
          out_x.selected = false;
        }
        dramas2.push(out_x);
      });
      this.dramas = dramas2;
      // console.log(this.dramas2);
    },
    save() {
      if (
        this.form.name == undefined ||
        this.form.context == undefined ||
        this.form.s_name == undefined
      ) {
        this.$message({
          message: "请输入必填项",
          type: "warning",
        });
      } else if (this.form.image == undefined || this.form.image == []) {
        this.$message({
          message: "请选择图片",
          type: "warning",
        });
      }
      let a = {
        id: this.form.id,
        name: this.form.name,
        heat: this.form.heat,
        context: this.form.context,
        number: this.form.number,
        s_id: this.form.s_id,
        s_name: this.form.s_name,
        image: this.form.image[0].base64,
        d_idnames: this.form.d_idnames,
      };
      this.$axios
        .post(this.$httpUrl + "/host/addUpdate/", a)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadPost();
            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
    },
    resetForm() {
      this.$refs.form.resetFields();
    }, // 编辑商品
    mod(row) {
      this.centerDialogVisible = true;
      this.form.image = [];
      this.form.id = row.id;
      this.form.name = row.name;
      this.form.heat = row.heat;
      this.form.context = row.context;
      this.form.number = row.number;
      this.form.s_name = row.s_name;
      this.form.d_idnames = row.d_idnames;
      this.form.image.push({
        base64: this.$httpUrl + "/image/" + row.image,
      });
      console.log(this.form);
    },
    add() {
      this.centerDialogVisible = true;
      this.form.image = [];
      this.form.id = "";
      this.form.name = "";
      this.form.heat = 0;
      this.form.context = "";
      this.form.number = 0;
      this.form.s_id = this.$store.getters.getMenu.s_id;
      this.form.s_name = this.$store.getters.getMenu.s_name;
      this.form.d_idnames = [];
      let dramas2 = [];
      this.dramas.forEach((x) => {
        x.selected = false;
        dramas2.push(x);
      });
      this.dramas = dramas2;
      console.log(this.dramas);
    },
    del(id) {
      this.$axios
        .get(this.$httpUrl + "/host/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    resetParam() {
      this.name = "";
    },
    loadPost() {
      this.tableData = [];
      this.$axios
        .post(this.$httpUrl + "/host/pagelist", {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          param: {
            name: this.name,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.tableData = res.data;
          } else {
            alert("获取数据失败");
          }
        });
    },
    loadDrama() {
      this.$axios
        .post(this.$httpUrl + "/drama/pagelist", {
          pageSize: -1,
          pageNum: this.pageNum,
          param: {
            name: "",
          },
        })
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.dramas = res.data;
          } else {
            alert("获取数据失败");
          }
        });
      console.log(this.dramas);
    },
  },
  beforeMount() {
    this.loadPost();
    this.loadDrama();
  },
};
</script>
  
  <style scoped>
</style>